/** @jsx jsx */
import { jsx, Box, Flex, Text, Image } from "theme-ui";
import { gql, useQuery } from "@apollo/client";

const GET_BLOG_POST = gql`
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      title
      author {
        id
        first_name
        last_name
      }
      thumbnail_url
      body
      created_at @dateFormat(format: "dd MMM yyyy")
    }
  }
`;

const Blog = (props) => {
  const blogId = props.location.search.split("?blog_id=")[1];

  const { loading, error, data } = useQuery(GET_BLOG_POST, {
    variables: {
      id: blogId,
    },
  });

  if (loading) return null;

  if (error) return <p>Error. Blog not found</p>;

  const post = data.getBlog;

  // blog view
  return (
    <Box
      className="blog"
      sx={{
        py: 3,
        p: {
          lineHeight: 1.5,
        },
        "h1, h2, h3, h4": {
          lineHeight: 0,
          mt: 3,
          mb: 0,
        },
      }}
    >
      <Box variant="layout.blog">
        <Text variant="heading" mb={1}>
          {post.title}
        </Text>

        <Text sx={{ fontSize: 0, mb: 3 }}>
          Posted by <b>Admin</b> •{" "}
          <span sx={{ color: "textMuted" }}>{post.created_at}</span>
        </Text>

        <Text sx={{ color: "textMuted", fontSize: 0 }}></Text>
      </Box>

      <Box variant="layout.blogImage">
        <Image src={post.thumbnail_url} />
      </Box>

      <Box
        variant="layout.blog"
        dangerouslySetInnerHTML={{ __html: post.body }}
      ></Box>
    </Box>
  );
};

export default Blog;
